@import "./variables.scss";

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  width: 100%;
  background-image: url(./Home/img/image1.png);
  background-repeat: repeat-y;
  background-size: cover;
  background-position: 0% 0%;
  background-attachment: fixed;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

/* customStyles.css */

@import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200&family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
