@import "../variables.scss";

.ctnS {
  width: 100%;
  height: 100vh;
  background-image: url(./img/p.png);
  background-repeat: no-repeat;
  background-size: cover;

  @include small {
    background-position: 15% 0%;
  }
  @include small2 {
    background-position: 30% 0%;
  }
  @include large {
    background-position: 40% 0%;
  }
  @include medium {
    background-position: 45% 0%;
  }
  @include smallSmall {
    background-position: 38% 0%;
  }
}
.ctnT {
  width: 600px;
  position: absolute;
  top: 50%;
  left: 72%;
  transform: translate(-50%, -50%);
  @include medium {
    left: 70%;
  }
  @include small {
    left: 65%;
  }
  @include small2 {
    left: 52%;
  }
  @include smallInte {
    left: 53%;
    width: 480px;
  }
  @include smallInteSmall {
    left: 53%;
    width: 350px;
  }
  @include smallSmall {
    left: 53%;
    width: 330px;
  }
}

@keyframes slidein3 {
  from {
    transform: translate(-100px, 0px);
  }
}

.name {
  margin: 0 auto;
  font-size: 55px;
  letter-spacing: 3px;
  font-family: "Dosis", sans-serif;
  color: rgb(255, 255, 255);
  @include smallInte {
    font-size: 35px;
  }
}

.txt {
  color: rgba(255, 255, 255, 0.836);
  font-size: 18px;
  letter-spacing: 7px;
  margin-top: 20px;
  font-family: "Dosis", sans-serif;

  @include smallInte {
    letter-spacing: 4px;
    font-size: 14px;
  }

  @include small1 {
    letter-spacing: 6px;
  }
}

.icon {
  cursor: pointer;
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: rgba(255, 255, 255, 0.836);
  animation-name: icon;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes icon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes icon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes icon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.icon:hover {
  font-size: 25px;
}
