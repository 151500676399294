@import "../variables.scss";

.ctnFooter {
  height: 40vh;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.ctnFooter1 {
  height: 100px;
  text-align: center;
  width: 1200px;
  @include medium {
    width: 900px;
  }
  @include small2 {
    width: 550px;
  }
  @include smallInte {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.navLin {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  letter-spacing: 4px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.836) !important;
  margin: 0px 50px 0px 50px;
  cursor: pointer;
  align-items: center;
  transition: all 0.1s;
  @include medium {
    margin: 0px 30px 0px 30px;
  }
  @include small2 {
    margin: 0px 10px 0px 10px;
    letter-spacing: 2px;
  }
  @include smallInteSmall {
    margin: 0px 5px 0px 5px;
    letter-spacing: 0px;
  }
  @include smallSmall {
    margin: 0px 4px 0px 4px;
    font-size: 9px;
  }
}

.navLin:hover {
  color: rgb(255, 255, 255) !important;
}

.navLinkDH {
  font-size: 40px;
  text-decoration: none;
  cursor: pointer;
  font-family: "Shadows Into Light", cursive;
  //   font-family: "Dosis", sans-serif;
  color: rgb(255, 255, 255) !important;
}

.navFooter {
  border-bottom: 1px solid rgb(133, 133, 133);
  padding-bottom: 20px;
}

.ctnIcons {
  padding-top: 20px;
}

.ctnDh {
  color: rgb(133, 133, 133);
  margin-bottom: 20px;
  font-size: 14px;

  @include smallInteSmall {
    width: 300px;
    text-align: center;
    margin-bottom: 20px;
  }
  @include smallSmall {
    margin: 0px 4px 0px 4px;
  }
}

.navLink1 {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  text-decoration: none;
  margin: 0px 30px 0px 30px;
  cursor: pointer;
  align-items: center;
}

.navLink1:hover {
  color: rgb(255, 255, 255) !important;
}

.icon {
  color: rgba(255, 255, 255, 0.719) !important;
  font-size: 20px;
  @include smallSmall {
    font-size: 18px;
  }
}
