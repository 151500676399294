@import "../../variables.scss";

.ctnSec {
  // background: linear-gradient(to bottom, white, #f5f5f5);
  background: white;

  padding-top: 60px;
  padding-bottom: 60px;
  color: rgb(0, 0, 0);
}

.ctnBnts {
  margin-bottom: 40px;
  padding-left: 100px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  @include small1 {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 40px;
  }
}

.text {
  font-family: "Dosis", sans-serif;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 50px;
}

.ctnTxt {
  text-align: center;
  margin: 0 auto;
  @include smallSmall {
    width: 300px;
  }
}

.titleSup {
  margin-bottom: 30px;
  font-size: 40px;
  letter-spacing: 2px;
  font-family: "Dosis", sans-serif;
}
