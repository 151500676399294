@mixin smallSmall {
  @media only screen and (max-width: 400px) {
    @content;
  }
}

@mixin smallInteSmall {
  @media only screen and (max-width: 500px) {
    @content;
  }
}

@mixin smallInte {
  @media only screen and (max-width: 600px) {
    @content;
  }
}

@mixin small1 {
  @media only screen and (max-width: 800px) {
    @content;
  }
}
@mixin small2 {
  @media only screen and (max-width: 900px) {
    @content;
  }
}

@mixin small {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}

@mixin medium {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin large {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}

@mixin large2 {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}

@mixin large3 {
  @media only screen and (max-width: 1800px) {
    @content;
  }
}

@mixin verMas {
  letter-spacing: 2px;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 600;
}

@mixin ctnCaruVer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  padding: 10px;
  margin: 0;
}

@mixin imagenHover {
  opacity: 0.07;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  -ms-transition: opacity 1s;
  transition: opacity 1s;
}

@mixin btn {
  width: 120px;
  padding: 0.4em;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 6px;
  cursor: pointer;
}

@mixin btnVerMasBlack {
  @include btn;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  background-color: #000000;
  transition: all 0.2s;
  &:hover {
    background-color: #000000dc;
  }
}

@mixin btnVerMasWhithe {
  @include btn;
  border: 1px solid rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  transition: all 0.2s;
  &:hover {
    background-color: #ffffffe7;
  }
}
