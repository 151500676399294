@import "../variables.scss";

.ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ctnTxt {
  text-align: center;
  margin: 0 auto;
  width: 60%;
  margin-top: 60px;
  @include smallSmall {
    width: 300px;
  }
}

.text {
  font-family: "Dosis", sans-serif;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  margin-bottom: 50px;

  // color: white;
}

.titleSup {
  color: rgb(255, 255, 255);
  margin-bottom: 30px;
  font-size: 40px;
  letter-spacing: 2px;
  font-family: "Dosis", sans-serif;
}

.ctnOverlaySoport {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.monitor {
  width: 60vw;
  border-radius: 9px;
  border: 20px solid;
  --tw-bg-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-bg-opacity));

  @include small {
    width: 80%;
  }
  @include small1 {
    width: 98%;
    border: 10px solid;
    --tw-bg-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }
}
.overlayImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 45vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;

  @include small {
    width: 50%;
  }
  @include small1 {
    width: 94%;
  }
  &:hover {
    filter: brightness(0.7);
    transform: translate(-50%, -50%) scale(1.005);
  }
}

.ctnSoport {
  position: relative;
}

.soport {
  height: 60px;
  width: 250px;
  background: linear-gradient(
    178.85deg,
    rgb(53, 53, 53) 1.02%,
    rgb(48, 48, 48) 1.03%,
    rgb(69, 69, 69) 50.63%,
    rgba(61, 61, 61, 0.818) 70.67%,
    rgba(75, 75, 75, 0.06) 100%
  );
  @include smallInte {
    width: 160px;
    height: 30px;
  }
  @include smallInte {
    height: 30px;
  }
}
.soportRadiurs {
  background-color: rgb(13, 13, 13);
  border-bottom-left-radius: 110px;
  border-bottom-right-radius: 110px;
  width: 60px;
  height: 30px;
  border-top: 0;
  position: absolute;
  top: 18.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include smallInte {
    width: 30px;
  }
  @include small1 {
    top: 30%;
    height: 20px;
  }
}

.ctnBnts {
  margin-bottom: 10px;
  @include smallInteSmall {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  @include small1 {
    margin-top: 20px;
  }
}

.ctnTextAndBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55%;
  height: 100px;
  margin-bottom: 50px;
  @include small {
    width: 80%;
  }

  @include small1 {
    width: 90%;
  }

  @include smallInte {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 220px;
    flex-direction: column;
  }
}
.textDesciption {
  font-family: "Dosis", sans-serif;
  color: #b5b5b5;
  font-size: 15px;
  letter-spacing: 2px;
  width: 78%;
  @include smallInte {
    width: 100%;
    padding: 20px 10px 20px 10px;
  }
}

.btnVerMas {
  @include btnVerMasWhithe;
}
