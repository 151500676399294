@import "../variables.scss";

.ctn {
  width: 100%;
  height: 100%;
  color: white;
  padding-top: 60px;
  padding-bottom: 60px;
  @include large {
    height: 100%;
  }
  @include smallInte {
    display: inline-block;
  }
}

.title {
  margin-bottom: 60px;
  font-size: 40px;
  letter-spacing: 2px;
  font-family: "Dosis", sans-serif;
  text-align: center;
  @include large {
    margin-bottom: 35px;
  }
  @include medium {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

.ctnTxt {
  display: flex;
  justify-content: space-around;
  @include medium {
    display: grid;
    grid-template-rows: 100px 100px;
    grid-template-columns: 1px 1px 1px 1px;
  }
}

.txt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation-name: mueve;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  @include smallInte {
    animation-name: mueve2;
  }
  @include smallInteSmall {
    animation-name: mueve3;
  }
  @include smallSmall {
    animation-name: mueve3;
  }
}

.tsJs {
  border: 1.2px solid white;
  padding: 5px 2px 0px 7px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  @include small {
    font-size: 10px;
  }
}

.icon {
  font-size: 35px;
  @include large2 {
    font-size: 28px;
  }
  @include medium {
    font-size: 25px;
  }
  @include small {
    font-size: 25px;
  }
  @include smallSmall {
    font-size: 20px;
  }
}

.p {
  font-size: 20px;
  letter-spacing: 2px;
  font-family: "Dosis", sans-serif;
  @include smallInte {
    font-size: 18px;
  }
  @include smallInteSmall {
    letter-spacing: 0px;
  }
  @include smallSmall {
    letter-spacing: 0px;
  }
}

@keyframes mueve {
  0% {
    left: 0px;
  }
  15% {
    left: 10px;
  }
  25% {
    left: 20px;
  }
  35% {
    left: 30px;
  }
  50% {
    right: 0px;
  }
  65% {
    left: -10px;
  }
  75% {
    left: -20px;
  }
  85% {
    left: -30px;
  }
  100% {
    left: -0px;
  }
}

@keyframes mueve2 {
  0% {
    left: 0px;
  }
  15% {
    left: 10px;
  }
  25% {
    left: 15px;
  }
  35% {
    left: 20px;
  }
  50% {
    right: 0px;
  }
  65% {
    left: -10px;
  }
  75% {
    left: -15px;
  }
  85% {
    left: -20px;
  }
  100% {
    left: -0px;
  }
}

@keyframes mueve3 {
  0% {
    left: 0px;
  }
  15% {
    left: 5px;
  }
  25% {
    left: 8px;
  }
  35% {
    left: 10px;
  }
  50% {
    right: 0px;
  }
  65% {
    left: -5px;
  }
  75% {
    left: -8px;
  }
  85% {
    left: -10px;
  }
  100% {
    left: -0px;
  }
}
