@import "../variables.scss";

.color {
  background-color: rgb(0, 0, 0);
  transition: background-color 0.5s, transform 0.5s;
}

.trasp {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s, transform 0.5s;
}

.cntCon {
  margin: 20px 70px 20px 70px;
  padding: 0px;
  transition: 0.5s;
  @include small {
    margin: 20px 40px 20px 40px;
  }
}

.minCntCon {
  margin: 2px 70px 2px 70px;
  padding: 0px;
  transition: 0.5s;
  @include small {
    margin: 2px 40px 2px 40px;
    height: 100%;
  }
}

.navCss {
  display: flex;
  justify-content: space-between;
  @include small {
    height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.ctnNav1 {
  position: static;
  align-items: center;
  @include small {
    align-items: flex-start;
    margin-top: 20px;
  }
}

.navLinkDH {
  font-size: 30px;
  margin-right: 30px;
  text-decoration: none;
  cursor: pointer;
  font-family: "Shadows Into Light", cursive;
  color: rgb(255, 255, 255) !important;
  @include small {
    margin: 0px;
    font-size: 25px;
  }
}

.navLink {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.836) !important;
  margin: 0px 15px 0px 15px;
  cursor: pointer;
  align-items: center;
  transition: 0.1s;
  @include small {
    margin: 30px 0px 0px 0px;
    font-size: 14px;
  }
}

.navLink:hover {
  transform: scale(1.1);
  @include small {
    transform: none;
  }
}

.ctnIcons {
  @include small {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
  }
}

.navLink1 {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.836) !important;
  margin: 0px 10px 0px 10px;
  cursor: pointer;
  align-items: center;
  transition: 0.1s;
  @include small {
    margin: 0px 30px 0px 0px;
  }
}

.navLink1:hover {
  transform: scale(1.1);
}

.icon {
  font-size: 18px;
}
