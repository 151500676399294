@import "../../variables.scss";

.CtnPricipalMovie {
  display: flex;
  justify-content: center;
  padding: 30px;
  // display: flex;
  // flex-direction: column;
  // @include large {
  //   height: 300px;
  // }
  @include medium {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
}

.image1 {
  text-align: center;
  // width: 100%;
  width: 50%;
  margin-right: 20px;
  object-fit: scale-down;
  // opacity: 90%;
  @include medium {
    width: 100%;
    margin-right: 0px;
  }
}

.ctnTextoCaru {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include medium {
    height: 275px;
  }
}

.title {
  font-family: "Dosis", sans-serif;
  letter-spacing: 2px;
  font-size: 22px;
  font-weight: 600;
  @include medium {
    margin-top: 15px;
  }
}

.textoCaru1 {
  font-family: "Dosis", sans-serif;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
}

.btnSeeMore {
  @include btnVerMasBlack;
}
